import { Validators } from '@angular/forms';
import { IFactory, Serializer, TreeStatus } from '@aa/common';

// local
import { IFormSerializer, FormConfigBuilder } from '../classes';
import { SelfServConfig  } from '../models';

const fb = new FormConfigBuilder<SelfServConfig>([
  { key: 'teamId', validators: [Validators.required]  },
  { key: 'domain', validators: [Validators.required, Validators.minLength(3)]  },
  { key: 'externalDomain', validators: [Validators.minLength(10)]  },

  { key: 'status',  defaultValue: TreeStatus.Live },
  { key: 'accessibleLinkText'},

  { key: 'libraryId', validators: [Validators.required] },
  { key: 'treeId' },

  { key: 'teamName' },

  { key: 'logoImage' },

  { key: 'homeLink' },
  { key: 'housingLink' },
  { key: 'feedbackLink' },
  { key: 'privacyLink' },
  { key: 'contactLink' },
  { key: 'hideContactBtn' },

  { key: 'gaKey', validators: [Validators.minLength(10)]  },
  { key: 'hotjarId'},

  { key: 'disclaimer' },
  { key: 'contactText' },
  { key: 'startText' },

  { key: 'selfReferralUrl' },
  { key: 'email' },
  { key: 'telephone' },
  { key: 'information' },
  { key: 'emergencyContact' },
  { key: 'translationUrl' },
  { key: 'address' },

  { key: 'headerAlignment' },
  { key: 'customCss' }
]);

export class SelfServConfigSerializer extends Serializer<SelfServConfig> implements IFormSerializer<SelfServConfig> {

  public getType(): IFactory<SelfServConfig> {
    return SelfServConfig;
  }

  public getName() {
    return 'SelfServConfig';
  }

  public buildForm() {
    return fb.build();
  }
}
