import { Validators } from '@angular/forms';

// local
import { IFormSerializer, FormConfigBuilder, CustomValidators } from '../classes';
import { AdviceSet  } from '../models';
import { Serializer } from '@aa/common';

const fb = new FormConfigBuilder<AdviceSet>([
  { key: 'name', validators: [Validators.required, Validators.minLength(3)]  },
  { key: 'description' },
  { key: 'slug' },
  { key: 'channelId'  },
  { key: 'tagId' },
  { key: 'snippetIds', defaultValue: [] },
  { key: 'relatedAdvice', defaultValue: [] },
  { key: 'keywords', validators: [CustomValidators.keywordList] },
  { key: 'alwaysInclude' },
  { key: 'quickExit'}
]);

export class AdviceSetSerializer extends Serializer<AdviceSet> implements IFormSerializer<AdviceSet> {

  public getType() {
    return AdviceSet;
  }

  public getName() {
    return 'Advice Set';
  }

  public buildForm() {
    return fb.build();
  }
}
