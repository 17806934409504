import { Guid } from './guid.type';
import { Item } from './item.model';
import { TreeStatus } from './tree-status.enum';
import { ITag, ITaggedItem } from './tag.interface';
import { Theme } from './theme.model';
import { TreeType } from './tree-type.enum';

export enum TreeItemType {
  Question = 0,
  AdviceSet = 1,
  MoreInfo = 2,
  Link = 3,
  Variable = 4
}

export enum SelfServMetaStatus {
  None = 0,
  Pending = 1,
  Ok = 2,
  Error = 4,
  SetupRequired = 8
}

export interface IQuestionResponse {
  text: string;
  nextType: TreeItemType;
  nextId?: Guid;
  href?: string;

  // selfserv only
  error?: string;
}

export interface ISelfServItem extends Item {
  errors?: string[];
}

export interface IQuestion extends ISelfServItem, ITaggedItem {
  name: string;
  text: string;
  slug: string;
  info: string;
  responses: IQuestionResponse[];
  entryPoint?: boolean;
  relatedQuestionId?: Guid;
}

export interface ISnippet extends ISelfServItem {
  description: string;
  data: string;
  source?: string;
}

export interface IAdviceSet extends ISelfServItem, ITaggedItem {
  name: string;
  description: string;
  slug: string;
  snippetIds: Guid[];
  tagId: Guid;
  keywords?: string;

  relatedAdvice: IQuestionResponse[];
  quickExit?: boolean;
}

export interface ISelfServContactInfo {
  selfReferralUrl?: string;
  telephone?: string;
  email?: string;
  information?: string;
  outOfHours?: string;

  openingHours?: string;
  translationUrl?: string;
  address?: string;
}

export interface ISelfServVariable {
  id: Guid;
  value?: string;
}

export interface ISelfServMeta {

  theme:  Theme;

  teamName: string;
  treeName: string;
  logoImage: string;
  headerImage: string;
  teamId: Guid;
  configId: Guid;
  domain: string;

  treeStatus: TreeStatus;
  treeType: TreeType;

  treeId: Guid;
  libraryId: Guid;
  serviceName: string;

  homeLink?: string;
  housingLink?: string;
  contactLink?: string;
  feedbackLink?: string;
  privacyLink?: string;

  contact: ISelfServContactInfo;
  hideContactBtn: boolean;

  created: Date;
  metaStatus: SelfServMetaStatus;
  url: string;
  dataUrl: string;

  disclaimer: string;
}

export interface ISelfServData {

  startText: string;
  lastEdited?: Date;

  questions: IQuestion[];
  snippets: ISnippet[];
  adviceSets: IAdviceSet[];
  tags: ITag[];
  variables: ISelfServVariable[];

  error: string;
}
